import { Outlet } from "@remix-run/react";

import "../../../global.css";

export default function Index() {
  return (
    <main className="font-sans">
      <Outlet />
    </main>
  );
}
